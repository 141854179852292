<template>
    <div class="jh-container">
        <div class="jh-ui-header">
            <h1>상담사 이석이력</h1>
            <div class="is-right">
            </div>
        </div>
        <div class="jh-search-form">
            <table>
                <colgroup>
                    <col width="55px">
                    <col width="250px">
                    <col width="50px">
                    <col width="150px">
                    <col width="150px">
                    <col width="120px">
                    <col width="70px">
                    <col width="250px">
                    <col width="80px">
                    <col width="150px">
                    <col>
                </colgroup>
                <tr>
                    <th><label>조회기간</label></th>
                    <td>
                        <div class="jh-cols">                        
                            <v-menu v-model="menuStartDate" :close-on-content-click="false" transition="scale-transition" min-width="auto" offset-y>
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="from" v-bind="attrs" v-on="on" class="jh-form" clearable append-icon="jh-icon-calendar is-blue" @keyup.enter="menuStartDate = false"
                                maxlength="10" @input="fromOnInput" @click:clear="dates[0] = ''" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3');"></v-text-field>
                              </template>
                              <v-date-picker class="jh-calendar" v-model="dates[0]" @input="menuStartDate = false" no-title locale="ko-KR" :max="dates[1]" :day-format="mixin_getDate" @change="startDate"></v-date-picker>
                            </v-menu>
                            <span class="jh-unit">~</span>                       
                            <v-menu v-model="menuEndDate" :close-on-content-click="false" transition="scale-transition" min-width="auto" offset-y>
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="to" v-bind="attrs" v-on="on" class="jh-form" clearable append-icon="jh-icon-calendar is-blue" @keyup.enter="menuEndDate = false"
                                maxlength="10" @input="toOnInput" @click:clear="dates[1] = ''" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3');"></v-text-field>
                              </template>
                              <v-date-picker class="jh-calendar" v-model="dates[1]" @input="menuEndDate = false" no-title locale="ko-KR" :min="dates[0]" :day-format="mixin_getDate" @change="endDate"></v-date-picker>
                            </v-menu>
                        </div>
                    </td>
                    <th><label>소속</label></th>
                    <td>
                        <v-select class="jh-form" :items="USER_ATTR_A" item-text="CD_NM" item-value="CD" placeholder="전체" v-model="formData.USER_ATTR_A"></v-select>
                    </td>
                    <td>
                        <v-select class="jh-form" :items="USER_ATTR_B" item-text="CD_NM" item-value="CD" placeholder="전체" v-model="formData.USER_ATTR_B"></v-select>
                    </td>
                    <td>
                        <v-select class="jh-form" :items="USER_ATTR_C" item-text="CD_NM" item-value="CD" placeholder="전체" v-model="formData.USER_ATTR_C"></v-select>
                    </td>
                    <th><label>상담사</label></th>
                    <td>
                        <div class="jh-cols">
                            <v-text-field class="jh-form is-col-fix" style="width: 80px;" name="name" id="id" v-model="formData.USER_ID"></v-text-field>
                            <v-text-field class="jh-form" name="name" id="id" v-model="formData.USER_NM"></v-text-field>
                            <v-btn class="jh-btn has-icon-only is-fill" @click="[setUser(), showDialog('P101801')]"><i class="jh-icon-search is-white"></i></v-btn>
                            <v-btn class="jh-btn has-icon-only" @click="clearData"><i class="jh-icon-refresh"></i></v-btn>
                        </div>
                    </td>
                    <th><label>이석상태</label></th>
                    <td>
                        <v-select class="jh-form" :items="mixin_common_code_get(this.common_code, 'TALK030_NOCACHE','전체')" placeholder="전체" v-model="formData.USER_STATUS_CD"></v-select>
                    </td>
                    <td class="has-search"><v-btn class="jh-btn is-search" @click="selectRtnCnslMgmt">조회</v-btn></td>
                </tr>
            </table>
        </div>
        <div class="jh-form-wrap">
            <div class="jh-ui-header">
                <h2>상담사 이석이력 리스트</h2>
            </div>            
            <!-- <data-tables
            id="divGrid01"
            dataTableClass=""
            :data-table-options="dataTableOptions"
            @click:row="rowClick"
            :paginationOptions="paginationOptions"
            ></data-tables> -->
            <v-data-table
            class="jh-grid"
            :headers="gridDataHeaders"
            :items="gridDataText"
            id="divGrid01"
            height="600px"
            :items-per-page="30"
            item-key="index"
            :page.sync="page"
            fixed-header
            hide-default-footer
            @page-count="pageCount = $event"
            @click:row="rowClick"
            :item-class="isActiveRow"
            ></v-data-table>
            <div class="jh-pager">
                <v-pagination v-model="page" :length="pageCount" :total-visible="totalVisible"></v-pagination> 
            </div>
        </div>
        <!-- 사용자조회 모달창 -->
        <v-dialog max-width="1000" v-model="DialogP101801">
            <dialog-P101801
            @hide="hideDialog('P101801')"
            :userInfo="userInfo"
            @input="userInfo = $event"
            @selectUser="[hideDialog('P101801'), setUserData()]"
            ></dialog-P101801>
            <template v-slot:body></template>
        </v-dialog>
        <!-- 사용자조회 모달창 -->
    </div>
</template>

<script>
import api from "../../store/apiUtil.js";
import { mixin } from "@/mixin/mixin.js";
import { mapGetters } from "vuex";
import dataTables from "@/components/DataTables";
import DialogP101801 from "./P101801.vue";
export default {
  name: "MENU_E020202", //name은 'MENU_' + 파일명 조합
  mixins: [mixin],
  components:{
    DialogP101801,
    dataTables
  },
  data: () => ({
    DialogP101801: false,
    userInfo:{},
    common_code: [],
    formData:{
      USER_ID : "",
      USER_NM : "",
      USER_NICK : "",
      USER_STATUS_CD : "",
      USER_ATTR_A : [],
      USER_ATTR_B : [],
      USER_ATTR_C : [],
      USER_ATTR_D : [],
      ASP_NEWCUST_KEY: "001",
    },
    USER_ATTR_A: [],
    USER_ATTR_B: [],
    USER_ATTR_C: [],
    USER_ATTR_D: [],
    menuStartDate: false,
    menuEndDate: false,
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    from: moment().format("YYYY-MM-DD"),
    to: moment().format("YYYY-MM-DD"),
    dates: [
      new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
    ],
    gridDataHeaders: [
      { text: "No", align: "center", value: "index", width: "50px", sortable: false },
      { text: "날짜", value: "REG_DATE", align: "center", width: "100px", sortable: false },
      { text: "사번", value: "USER_ID", align: "center", width: "100px", sortable: false },
      { text: "상담사명", value: "USER_NM", align: "center", width: "100px", sortable: false },
      { text: "닉네임", value: "USER_NICK", align: "center", width: "100px", sortable: false },
      { text: "소속A", value: "ATTR_DIV_NM_A", align: "center", width: "100px", sortable: false },
      { text: "소속B", value: "ATTR_DIV_NM_B", align: "center", width: "100px", sortable: false },
      { text: "소속C", value: "ATTR_DIV_NM_C", align: "center", width: "100px", sortable: false },
      { text: "소속D", value: "ATTR_DIV_NM_D", align: "center", width: "100px", sortable: false },
      { text: "이석상태", value: "USER_STATUS_CD", align: "center", width: "100px", sortable: false },
      { text: "이석시작시간", value: "TALK_START_DT", align: "center", width: "100px", sortable: true },
      { text: "이석종료시간", value: "TALK_END_DT", align: "center", width: "100px", sortable: true },
    ],
    gridDataText: [],
    page: 1,
    pageCount: 0,
    itemsPerPage: 13,
    totalVisible: 10,

    ROW:'',
    dataTableOptions: {
        fixedHeader: true,
        hideDefaultFooter: true,
        height: '600px',
        itemKey: 'index',
        itemsPerPage: 50,
        noDataText: "조회된 결과가 없습니다.",
        page: 1,
        singleSelect: true,
        itemClass: "isActiveRow"
    },
    paginationOptions: {
        totalVisible: 10
    },
  }),
  methods: {
    selectValidation(){
      if (this.formData.ASP_NEWCUST_KEY == "" || this.formData.ASP_NEWCUST_KEY == undefined) {
        this.common_alert("회사를 먼저 선택해주세요.","error");
        return false;
      }
      return true;
    },
    showDialog(type) {
      if(!this.selectValidation()) return;

      this[`Dialog${type}`] = true;
    },
    hideDialog(type) {
      this[`Dialog${type}`] = false;
    },
    setUser(){
      let user = {
        ASP_CUST_KEY : this.formData.ASP_NEWCUST_KEY,
        USER_ID: this.formData.USER_ID,
        USER_NICK:this.formData.USER_NICK,
        USER_NM:this.formData.USER_NM,
      };
      this.userInfo = user;
    },
    setUserData(){
      this.formData.USER_ID = this.userInfo.USER_ID;
      this.formData.USER_NM = this.userInfo.USER_NM;
      this.formData.USER_NICK = this.userInfo.USER_NICK;
    },
    fromOnInput: function() {
      // 날짜 포맷과 일치하고 입력일자에 문제가 없을 때만 datepicker에 반영
      if (/^\d{4}-\d{2}-\d{2}$/.test(this.from) && moment(this.from).isValid()) {
        // 종료일자보다 이후를 입력했으면 종료일자로 변경
        if (moment(this.from).isAfter(this.dates[1])) {
          this.$nextTick(() => {
            this.from = this.dates[1];
          });
        }

        this.$nextTick(() => {
          this.dates[0] = this.from;
          // datepick 다시 랜더링
          this.menuStartDate = false;
          this.menuStartDate = true;
        });
      }
    },
    startDate(e) {
      this.from = e;
    },
    toOnInput: function() {
      // 날짜 포맷과 일치하고 입력일자에 문제가 없을 때만 datepicker에 반영
      if (/^\d{4}-\d{2}-\d{2}$/.test(this.to) && moment(this.to).isValid()) {
        // 시작일자보다 이전을 입력했으면 시작일자로 변경
        if (moment(this.to).isBefore(this.dates[0])) {
          this.$nextTick(() => {
            this.to = this.dates[0];
          });
        }

        this.$nextTick(() => {
          this.dates[1] = this.to;
          // datepick 다시 랜더링
          this.menuEndDate = false;
          this.menuEndDate = true;
        });
      }
    },
    endDate(e) {
      this.to = e;
    },
    async selectRtnCnslMgmt() {
    //   if(!this.selectValidation()) return;

      let URLData = "/api/chat/status/agent-away-history/list";
      let HEADER_SERVICE = "chat.status.agent-away-history";
      let HEADER_METHOD = "list";
      let HEADER_TYPE = "BIZ_SERVICE";
      let HEADER_ROW_CNT = 50;
      let HEADER_PAGES_CNT = 1;

      let jsonData = this.formData;
      jsonData.SEARCH_DATE_FROM = this.dates[0].replace(/-/gi, "");
      jsonData.SEARCH_DATE_TO = this.dates[1].replace(/-/gi, "");
      jsonData.ASP_CUST_KEY = this.formData.ASP_NEWCUST_KEY;

      //이 작업 안할시 Observer 객체가 들어가서 데이터 미선택시 조회가 안됨..
      jsonData.USER_ATTR_A = this.formData.USER_ATTR_A.length == 0 ? "" : this.formData.USER_ATTR_A;
      jsonData.USER_ATTR_B = this.formData.USER_ATTR_B.length == 0 ? "" : this.formData.USER_ATTR_B;
      jsonData.USER_ATTR_C = this.formData.USER_ATTR_C.length == 0 ? "" : this.formData.USER_ATTR_C;
      jsonData.USER_ATTR_D = this.formData.USER_ATTR_D.length == 0 ? "" : this.formData.USER_ATTR_D;

      await api
        .post(URLData, jsonData, {
          head: {
            SERVICE: HEADER_SERVICE,
            METHOD: HEADER_METHOD,
            TYPE: HEADER_TYPE,
            ROW_CNT: HEADER_ROW_CNT,
            PAGES_CNT: HEADER_PAGES_CNT,
            ASYNC: false,
          },
        })
        .then((response) => {
          const jsonObj = JSON.parse(JSON.stringify(response.data.DATA));
          for (let i = 0; i < jsonObj.length; i++) {
            jsonObj[i].index = i + 1;
          }
          this.gridDataText = jsonObj;
          //최근 업데이트 날짜 갱신
        })
        .catch((err) => {
          alert(err);
        });
    },
    async changeAspCustKey(){
      let aspNewcustKey = "001";
      //회사구분 변경시 소속 콤보박스에 selected 된것 초기화

      this.USER_ATTR_A = await this.mixin_attr_get(aspNewcustKey, "A","전체");
      this.USER_ATTR_B = await this.mixin_attr_get(aspNewcustKey, "B","전체");
      this.USER_ATTR_C = await this.mixin_attr_get(aspNewcustKey, "C","전체");
      this.USER_ATTR_D = await this.mixin_attr_get(aspNewcustKey, "D","전체");

      this.formData.USER_ATTR_A = "";
      this.formData.USER_ATTR_B = "";
      this.formData.USER_ATTR_C = "";
      this.formData.USER_ATTR_D = "";
    },
    clearData(){
      this.formData.USER_ID = '';
      this.formData.USER_NM = '';
      this.formData.USER_NICK = '';
    },

    rowClick (item) {
      console.log("item", item);
      this.ROW = item.index;

    },

    isActiveRow(item){
      const activeClass = item.index === this.ROW ? 'is-active' : '';
      return activeClass;
    },
  },
  computed: {
    dateRangeText() {
      var newStartDate = this.dates[0];
      var newEndDate = this.dates[1];
      return newStartDate + " ~ " + newEndDate;
    },
    ...mapGetters({
      //store에 저장된 사용자의 company 정보를 가져온다
      aspCustList: "userStore/GE_USER_COMPANY",
    }),
  },
  async mounted(){
    let codeName = ["TALK030_NOCACHE","TALK001"];
    this.common_code = await this.mixin_common_code_get_all(codeName);
    this.changeAspCustKey();
  },
  
    created() { 
        this.dataTableOptions.headers = this.gridDataHeaders;
        this.dataTableOptions.items = this.gridDataText;
    }
};
</script>

<style>
</style>